/*
 * extractMarkerData
 * the application places the marker data on the map div as a JSON string
 * ----------
 * map_div: handle to the map div
 */
function extractMarkerData(map_div) {
  markerDataJson = map_div.dataset.markers
  return JSON.parse(markerDataJson);
}

function drawMarker(map, markerInfo, markerImage, markerShadow) {
  var markerLatLng = new google.maps.LatLng(markerInfo.latitude, markerInfo.longitude);
  var marker = new google.maps.Marker(
  {
    position: markerLatLng,
    map:      map,
    title:    markerInfo.title,
    icon:     markerImage,
    shadow:   markerShadow
  });

  google.maps.event.addListener( marker, 'click',
    function(event) {
      window.location = markerInfo.path;
    }
  );
}

function drawTheMap(map_div) {
  var latlng = new google.maps.LatLng( 40.95, -109.62 );
  var myOptions = {
    zoom:      11.5,
    center:    latlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP
  };
  return new google.maps.Map(map_div, myOptions);
}

/*
 * initMainMap
 * This function serves as the callback for Google Maps
 */
window.initMainMap = function() {
  var map_div = document.getElementById("navMapDiv");

  var markerData = extractMarkerData(map_div);
  var map = drawTheMap(map_div);

  //var markerImage = new google.maps.MarkerImage( '/assets/marker.png',
  var markerImage = new google.maps.MarkerImage(markerData.marker_image_path,
       new google.maps.Size(32.0, 37.0),
       new google.maps.Point(0, 0),
       new google.maps.Point(16, 35) );

  var markerShadow = new google.maps.MarkerImage(markerData.shadow_image_path,
       new google.maps.Size(51.0, 37.0),
       new google.maps.Point(0, 0),
       new google.maps.Point(16, 35) );

  markerData.markers.forEach(function(markerDatum, index) {
    drawMarker(map, markerDatum, markerImage, markerShadow);
  });
}


